import { Box, Container, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { CustomButton, CustomLoader } from "../../Components/Common";
import BookService from "../Dashboard-Home/BookService";
import Checkout from "../Appointments/Checkout";
import BuyCredit from "../Appointments/BuyCredit";
import BookingSuccess from "../Appointments/BookingSuccess";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLocalData, setLocalData, clearLocalData } from "../../Utils";
import ItemDetail from "../Shop/ItemDetail";
import Cart from "../Shop/Cart";
import PaymentCheckout from "../Shop/Checkout";
import {
  GetAllAppointmentsDetail,
  GetUpcomingAppointments,
} from "../../Services/APIs";
import backArrow from "../../assets/images/orangeArrow.svg";
import { ExploreContext, LocationDataContext } from "../../App";
import { ProfileModals } from "../../Components/Modals";
import { ApiServices } from "../../Services/ApiServices";
import { API_URL_2 } from "../../Utils/constants";
import { GetGuestData } from "../../Services/APIs/client";

function ExploreDetails({ clientDetail }) {
  const navigate = useNavigate();
  const Base_URL = API_URL_2;
  const { setGlobalLocationId } = useContext(LocationDataContext);
  const [activeSteps, setActiveSteps] = useState(0);
  const locationId = getLocalData("locationId");
  const [bookingType, setBookingType] = useState("");
  const [creditOptions, setCreditOptions] = useState([]);
  const [isCreditBuy, setCreditBuy] = useState(false);
  const [shopCheckoutTotal, setShopCheckoutTotal] = useState();
  const [summary, setSummary] = useState(false);
  const [selectedPetCheckout, setSelectedPetCheckout] = useState({});
  const [selected, setSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams("");
  const [loader, setLoader] = useState(false);
  const paramsLocationId = searchParams.get("location");
  const paramsOccuranceId = searchParams.get("occurance");
  const paramFranchiseeId = searchParams.get("franchiseeId");
  const [openInvalidUrlMsg, setOpenInvalidUrlMsg] = useState(false);
  const [purchseIdValue, setPurchseIdValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    dog: {},
    serviceName: {},
    fromDate: "",
    sortKey: "",
    categoryName: {},
    roomName: {},
    notes: "",
    trainerAvailability: [],
    trainerName: "",
    trainerId: "",
    trainerProfileImage: "",
    startTime: "",
    endTime: "",
    creditsAvailiable: true,
    creditsValue: {},
    cardName: "",
    date: "",
    cardNumber: "",
    cvv: "",
  });

  useEffect(() => {
    if (paramsLocationId && paramsOccuranceId && paramFranchiseeId) {
      handleSelected();
      handleGetLocationDetails();
    } else {
      navigate("/account");
    }
  }, []);

  const { isGuest, setIsGuest } = useContext(ExploreContext);
  const [allScheduleRoute, setAllScheduleRoute] = useState(false);
  let token = getLocalData("token");

  useEffect(() => {
    if (!token) {
      setIsGuest(true);
      setLocalData("isGuest", true);
    }
  }, []);

  const handleGetLocationDetails = async () => {
    setLoader(true);
    let franchiseeId = encodeURIComponent(
      getLocalData("franchiseeId") || paramFranchiseeId
    );
    let locationId = encodeURIComponent(getLocalData("locationId"));

    try {
      const response = await ApiServices.get(
        `${Base_URL}locations/getLocationById?franchiseeId=${franchiseeId}&locationId=${locationId}&status=1`
      );
      if (response?.statusCode === 200) {
        setLocalData("franchiseeId", response?.data?.Item?.franchiseeId);
        setLocalData("locationName", response?.data?.Item?.locationName);
        getGuestClientId();
        setLoader(false);
      } else {
        setLoader(false);
        setOpenInvalidUrlMsg(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getGuestClientId = async () => {
    let location = encodeURIComponent(paramsLocationId);
    let response = await GetGuestData(location);
    if (response?.statusCode === 200) {
      setLocalData("clientId", response?.data?.clientId);
    }
  };

  const handleSelected = () => {
    let path = `${window?.location?.pathname}?location=${encodeURIComponent(
      paramsLocationId
    )}&occurance=${encodeURIComponent(
      paramsOccuranceId
    )}&franchiseeId=${encodeURIComponent(paramFranchiseeId)}`;
    if (!token) {
      setLocalData("redirectionRoute", path);
      setLocalData("locationId", paramsLocationId);
      setGlobalLocationId(paramsLocationId);
    }
    setLoader(true);
    const payLoad = {
      locationId: paramsLocationId,
      occurrenceId: paramsOccuranceId,
    };
    GetAllAppointmentsDetail(payLoad).then((response) => {
      if (
        response?.statusCode === 200 &&
        response?.message ===
          "No Records Found! Please Check appointmentId and SortKey"
      ) {
        setLoader(false);
        setOpenInvalidUrlMsg(true);
      } else if (response?.statusCode === 200) {
        let filteredData = [];
        filteredData[0] = response?.data;
        setSelected(filteredData);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const childComponents = [
    {
      title: "Book Service",
      component: (
        <BookService
          handleBack={() => navigate("/explore")}
          handleNext={() => setActiveSteps(1)}
          setActiveSteps={setActiveSteps}
          selected={selected}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setBookingType={setBookingType}
          setAllScheduleRoute={setAllScheduleRoute}
          allScheduleRoute={allScheduleRoute}
          purchseIdValue={purchseIdValue}
          setPurchseIdValue={setPurchseIdValue}
        />
      ),
    },
    {
      title: "Checkout",
      component: (
        <Checkout
          handleBack={() => setActiveSteps(3)}
          //   handleNext={() => setActiveSteps(7)}
          selected={selected}
          clientDetail={clientDetail}
          //   buyNow={() => setActiveSteps(3)}
          bookingType={bookingType}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          creditOptions={creditOptions}
          setCreditOptions={setCreditOptions}
          setPage={setActiveSteps}
        />
      ),
    },
    {
      title: "Buy Credit",
      component: (
        <BuyCredit
          handleBack={() => setActiveSteps(3)}
          selected={selected}
          //   handleNext={() => setActiveSteps(5)}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          isCreditBuy={isCreditBuy}
          setCreditBuy={setCreditBuy}
        />
      ),
    },
    {
      title: "Credit Details",
      component: (
        <ItemDetail
          //   handleBack={() => setActiveSteps(5)}
          handleNext={() => setActiveSteps(4)}
          selected={selectedOption.creditsDetails}
          selectedType={bookingType}
          globalLocationId={locationId}
        />
      ),
    },
    {
      title: "Cart",
      component: (
        <Cart
          //   handleBack={() => setActiveSteps(6)}
          setShopCheckoutTotal={setShopCheckoutTotal}
          handleNext={() => setActiveSteps(5)}
          summary={summary}
          setSummary={setSummary}
          setSelectedPetCheckout={setSelectedPetCheckout}
        />
      ),
    },
    {
      title: "Check Out Payment",
      component: (
        <PaymentCheckout
          handleNext={() => setActiveSteps(0)}
          shopCheckoutTotal={shopCheckoutTotal}
          selectedPetCheckout={selectedPetCheckout}
        />
      ),
    },
    {
      title: "",
      component: (
        <BookingSuccess
          handleBack={() => setActiveSteps(0)}
          bookingType={"Enrollment"}
          setPage={setActiveSteps}
          setSelectedOption={setSelectedOption}
        />
      ),
    },
  ];

  const handleBackClick = () => {
    if (activeSteps === 5 || activeSteps === 6) {
      setActiveSteps(0);
    } else if (activeSteps === 4 && summary) {
      setSummary(false);
    } else if (activeSteps > 0) {
      setActiveSteps(activeSteps - 1);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      {!isGuest && <CustomLoader IsLoading={loader} />}
      <Container className="appointment-container">
        <Box className="appointment-header">
          <Box className="top-header shop-header">
            <Box className="first-box">
              <CustomButton
                className="arrow-btn"
                color="#E35205"
                icon={backArrow}
                backgroundColor="#E7EFF9"
                onClick={handleBackClick}
              />
              <Typography className="header-text">
                {childComponents[activeSteps]?.title}
              </Typography>
            </Box>
          </Box>
        </Box>
        {childComponents[activeSteps]?.component}
      </Container>
      <ProfileModals
        open={openInvalidUrlMsg}
        type={"InvalidUrlMsg"}
        handleClose={() => {
          setOpenInvalidUrlMsg(false);
          clearLocalData();
          navigate("/account");
          window.location.reload();
        }}
      />
    </>
  );
}

export default ExploreDetails;
