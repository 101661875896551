import { API_URL_3 } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";

const clientId = encodeURIComponent(getLocalData("clientId"));
const Base_URL = API_URL_3;

export const getAllTrophyByclient = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}trophy/getAllTrophyByclient?clientId=${clientId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllTrophy = async () => {
  try {
    return await ApiServices.get(`${Base_URL}trophy/getAllTrophy?status=1`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getCurrentYearClass = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}client/getCurrentYearClassCompleted`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getOverallClassCompleted = async () => {
  try {
    return await ApiServices.get(`${Base_URL}client/getOverallClassCompleted`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
